import useSWR from 'swr'


async function fetcher(url) {
    let response
    try {
        response = await fetch(url)
    } catch (error) {
        throw new Error("Network error occurred while fetching notifications.")
    }
    if (!response.ok) throw new Error(response.statusText)
    return await response.json()
}

export function useNotifications(apiUrl) {
    const { data, error, isLoading, isValidating, mutate } = useSWR(apiUrl, fetcher, {
        revalidateOnFocus: false,
        // refreshing notifications with new requests (TODO: websocket version)
        refreshInterval: 30_000,
        // keepPreviousData: true,
        onError: (error) => console.log(error.message)
    });

    return {
        notifications: data,
        isLoading,
        isValidating,
        isError: error,
        mutate,
    }
}


export async function sendClearRequest(url, { arg }) {
    let formData = new FormData()
    formData.append('csrfmiddlewaretoken', arg)

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    })

    if (!response.ok) throw new Error(response.statusText)
    return response
}