import React from 'react'  // Build dependency, even if not used
import { createRoot } from 'react-dom/client'
import useSWRMutation from 'swr/mutation'
import { useNotifications, sendClearRequest } from './notificationsHooks'


function NotificationsDropdownButton({ notificationsCount, onExpand }) {
    return (
        <button className="nav-link p-2 ms-md-2" role="button" id="dropdownMenuButton"
            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true"
            data-bs-auto-close="outside" onClick={(e) => {
                // Upon getting this point buttons attr aria-expanded is already set, so check if it is true
                if (e.currentTarget.getAttribute('aria-expanded') === 'true') {
                    onExpand()
                }
            }}>
            <i className="fa-regular fa-bell fa-xl" title="Notifications" alt="notifications"></i>

            {notificationsCount > 0 ?
                <span className="position-absolute top-14 start-86 translate-middle badge rounded-pill bg-primary">
                    {notificationsCount}<span className="visually-hidden">unread messages</span>
                </span> :
                <></>
            }
        </button>
    )
}

function Notification({ sender, verb, timeSince, objUrl }) {
    return (
        <li className="dropdown-item text-wrap rounded m-0 py-2">
            <a className="nav-link link-dark text-decoration-none py-0" href={objUrl}>
                {verb} {sender}
                <br /><span className="small text-muted text-nowrap">{timeSince}</span>
            </a>
        </li>
    )
}

function NotificationsList({ notifications }) {
    return (
        <ul className="list-unstyled mb-0">
            {notifications.map(noti => {
                return <Notification
                    key={noti.id}
                    sender={noti.sender_name}
                    verb={noti.verb}
                    timeSince={noti.time_since}
                    objUrl={noti.object_url}
                />
            })}
        </ul>
    )
}

function ClearNotificationsButton({ isClearing, clearHandler }) {
    return (
        <div className="text-center py-1">
            <button disabled={isClearing} onClick={clearHandler}
                className="btn btn-link text-decoration-none py-0">
                {
                    isClearing ?
                        'Clearing...' :
                        'Clear all'
                }
            </button>
        </div>
    )
}


function NotificationsDropdown({ children, notificationsCount, onExpand }) {
    return (
        <div className="dropdown">
            <NotificationsDropdownButton notificationsCount={notificationsCount} onExpand={onExpand} />
            <div className="dropdown-menu dropdown-menu-end dropdown-animated shadow-lg border-0 notifications-dropdown-menu py-0 mt-4 me-2 me-sm-0">
                {children}
            </div>
        </div>
    )
}


function NotificationsWidget({ apiUrl, clearUrl, csrfToken }) {
    const { notifications, isLoading, isValidating, isError, mutate } = useNotifications(apiUrl)

    const { trigger, isMutating } = useSWRMutation(clearUrl, sendClearRequest, {
        onSuccess: () => mutate(),  // Set up notifications revalidation after successful clearing
        onError: error => console.log('Network error occurred while clearing notifications: ' + error)
    })

    if (isLoading || isValidating) return (
        <NotificationsDropdown notificationsCount={0} onExpand={mutate}>
            <div className="text-center my-3">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </NotificationsDropdown>
    )

    if (isError) {
        return (
            <NotificationsDropdown notificationsCount={0} onExpand={mutate}>
                <p className="text-center m-4">No connection</p>
            </NotificationsDropdown>
        )
    }

    return (
        <NotificationsDropdown notificationsCount={notifications.count} onExpand={mutate}>
            <NotificationsList notifications={notifications.results} />
            {
                notifications.count > 0 ?
                    <ClearNotificationsButton isClearing={isMutating} clearHandler={() => trigger(csrfToken)} />
                    :
                    <p className="text-center m-4">There is no new notifications</p>
            }
        </NotificationsDropdown>
    )
}


const rootElement = document.getElementById('notificationsWidgetRoot')
// Render only if there is root (root is rendered only when user is logged in) 
if (rootElement !== null) {
    const csrfToken = rootElement.getAttribute('csrfmiddlewaretoken')

    const root = createRoot(rootElement)
    root.render(<NotificationsWidget
        apiUrl={'/api/v1/notifications/received/'}
        clearUrl={'/notifications/delete-all/'}
        csrfToken={csrfToken}
    />)
}